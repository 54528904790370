<template>
  <div class="flex flex-col">
    <ExpandableListItem
      v-for="item in props.items"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script setup lang="ts">
import { MenuItemDto } from '~/api/organizations';
import ExpandableListItem from './expandable-list-item.vue';

type Props = {
  items: MenuItemDto;
};
const props = defineProps<Props>();
</script>
